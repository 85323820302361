import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
export const pageQuery = graphql`
  {
    refuge: file(
      relativePath: {
        eq: "The Refuge (Bushfire protection)/Refuge 3 (3 Segment, original)/Renders/External/External_0200.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    loft: file(relativePath: { eq: "exteriors/DOMUS Modern connected ELEV.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    {
      /*--- Conversion time: 0.407 seconds.
      
      Using this Markdown file:
      1. Cut and paste this output into your source file.
      2. See the notes and action items below regarding this conversion run.
      3. Check the rendered output (headings, lists, code blocks, tables) for proper
        formatting and use a linkchecker before you publish this page.
      Conversion notes:
      * Docs to Markdown version 1.0β17
      * Fri Jan 31 2020 06:25:40 GMT-0800 (PST)
      * Source doc: https://docs.google.com/open?id=1_3_4pEu4Sx4igQPiJllKdZuDXjUkicKICYbT7ApCZw0
      ---*/
    }
    <p>{`At Fluid Solar Thermal, we have developed a revolutionary system of renewable energy collection and storage. Now, we aim to dispense this technology through our products: affordable and energy-efficient modular constructions. By combining our solar thermal energy technology with environmentally-conscious design and rapid construction times, we aim to minimise our environmental impact while providing products that are truly affordable, and therefore accessible. Our modular designs cover a range of uses including: permanent family homes; holiday homes; `}<a parentName="p" {...{
        "href": "#emergency-rebuilding"
      }}>{`emergency refuges`}</a>{` with 1 hour fire-rated construction methods, additions, and large-scale commercial developments, all built in South Australia using local materials where possible.`}</p>
    <h2 {...{
      "id": "affordable-housing"
    }}>{`Affordable Housing`}</h2>
    <p>{`The original vision of Fluid Solar Thermal was to bring our patented solar-based energy collection technology to all types of Australians by creating sustainable, energy-efficient homes. This intention has now been realised, with a wide range of homes that can all be assembled to function off-grid, with reduced reliance on the grid or with a standard grid connection, and which can cut typical energy costs for occupants by as much as 90%.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/62b2d732f4819fea76415c7b9157b51f/2972e/gawler-home.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGlVRGjCv/EABsQAAEEAwAAAAAAAAAAAAAAAAECEhMhAAMR/9oACAEBAAEFAoQ4VuUpYLOmC4c//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxABAAICAwAAAAAAAAAAAAAAAQARAiEQUmH/2gAIAQEABj8CvJ15E68ayRitNzbP/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARITFRYf/aAAgBAQABPyG+xcZADM5DFOjA+sKlnuIuSjD/2gAMAwEAAgADAAAAEEwP/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QiP/EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EIf/xAAcEAEBAAMBAAMAAAAAAAAAAAABEQAhMXFhgaH/2gAIAQEAAT8QuJ0yiuh+sL24FsnnXCbfiIw5jBDBC56ccV8pACtfBmk0ZOc/c//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Gawler home",
            "title": "Gawler home",
            "src": "/static/62b2d732f4819fea76415c7b9157b51f/7ef33/gawler-home.jpg",
            "srcSet": ["/static/62b2d732f4819fea76415c7b9157b51f/c635c/gawler-home.jpg 500w", "/static/62b2d732f4819fea76415c7b9157b51f/7e3f6/gawler-home.jpg 1000w", "/static/62b2d732f4819fea76415c7b9157b51f/7ef33/gawler-home.jpg 2000w", "/static/62b2d732f4819fea76415c7b9157b51f/2972e/gawler-home.jpg 2592w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Modular home design, where pre-fabricated components are brought together for each new project, significantly reduces the cost and build times required for these projects, meaning that your permanent residence or holiday home can be assembled affordably and in a matter of a few days. Our designs can cater for anything from one-bedroom, one-living dwellings to those with five bedrooms or more. We can deliver and assemble a complete four bedroom home in less than four days on a prepared site, whereas it would take around six to twelve months using conventional on site building techniques.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/795d7fa38d45f8aa938f1743f978f8df/b324a/Gable_2_Luxury_off_grid_lotus_birdseye.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAFdfPnMxSB//8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAEgMQEf/aAAgBAQABBQJAI6Y6ulCnYxJ1/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/Aaf/xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhAhAxUWH/2gAIAQEABj8CnLjw6IbRTLcvX//EABsQAAMBAQADAAAAAAAAAAAAAAABESExQWGx/9oACAEBAAE/IcerBe2vWdHLrS4ytwXkebfM4f/aAAwDAQACAAMAAAAQay//xAAWEQADAAAAAAAAAAAAAAAAAAAQETH/2gAIAQMBAT8Qhj//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAAT//EABsQAAICAwEAAAAAAAAAAAAAAAERADEhQfBh/9oACAEBAAE/EHnsZFvtRaBAZmidN3C5JBpZlrHCIdcUUgMmvAjoQDP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Lotus birdseye",
            "title": "Lotus birdseye",
            "src": "/static/795d7fa38d45f8aa938f1743f978f8df/7ef33/Gable_2_Luxury_off_grid_lotus_birdseye.jpg",
            "srcSet": ["/static/795d7fa38d45f8aa938f1743f978f8df/c635c/Gable_2_Luxury_off_grid_lotus_birdseye.jpg 500w", "/static/795d7fa38d45f8aa938f1743f978f8df/7e3f6/Gable_2_Luxury_off_grid_lotus_birdseye.jpg 1000w", "/static/795d7fa38d45f8aa938f1743f978f8df/7ef33/Gable_2_Luxury_off_grid_lotus_birdseye.jpg 2000w", "/static/795d7fa38d45f8aa938f1743f978f8df/2d501/Gable_2_Luxury_off_grid_lotus_birdseye.jpg 3000w", "/static/795d7fa38d45f8aa938f1743f978f8df/b324a/Gable_2_Luxury_off_grid_lotus_birdseye.jpg 4000w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "emergency-rebuilding"
    }}>{`Emergency Rebuilding`}</h2>
    <p>{`Our `}<strong parentName="p">{`Refuge`}</strong>{` models have been designed in response to the crisis of individuals being left homeless in natural disasters across Australia.`}</p>
    <div>
  <Img fluid={props.data.refuge.childImageSharp.fluid} mdxType="Img" />
    </div>
    <p>{`These builds can be assembled quickly and on a scale to suit the individual circumstances of those affected, from single-room structures to entire family homes. Our construction and materials are rated for one hour of fire exposure, and every space can provide secure access to essential amenities. Discover more about these models here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/modular-homes/refuge-standard"
        }}>{`The Refuge`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/modular-homes/refuge-compact"
        }}>{`Refuge Compact`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/modular-homes/refuge-18-segment"
        }}>{`Refuge Extended`}</a></li>
    </ul>
    <h2 {...{
      "id": "additions-and-extensions"
    }}>{`Additions and Extensions`}</h2>
    <p>{`We have a range of build options ideal for those looking to create a granny-flat-style addition to their home. These cabins can create a new source passive income from a rental space, or act as a pool house, den or games room.`}</p>
    <p>{`For added versatility, our projects can be elevated to create a loft and undercover carport.`}</p>
    <div>
    <Img fluid={props.data.loft.childImageSharp.fluid} mdxType="Img" />
    </div>
    <p>{`Our projects are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`High-quality.`}</strong>{` Our homes have been developed with a team of engineers and architects and are assembled in South Australia using local materials where possible.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cost-effective.`}</strong>{` Our modular building system, where structures are securely assembled from pre-fabricated components, allows you to add value and space to your home while requiring a fraction of the expense associated with traditional building methods. Once built, our structures require a fraction of the energy to operate, lowering energy costs or eliminating them entirely.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fast and reliable.`}</strong>{` Your project can come together in only a few days, removing the uncertainty and delays common in traditional building.`}</li>
      <li parentName="ul"><strong parentName="li">{`Convenient.`}</strong>{` Our cabins can be transported on the width of a standard trailer, allowing easy installation in urban settings. The parts arrive pre-wired and pre-plumed. Our foundation blocks can account for sloped ground, and require minimal excavation.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sustainable.`}</strong>{` Our patented solar-thermal technology means that these spaces can supply their own reliable, sustainably-sourced energy. They can operate completely free of the grid, with a reduced grid connection, or with a standard connection, based on your preference. The structures have been created in order to maintain an even internal temperature, reducing the substantial burden that heating and cooling generally impose on energy systems.`}</li>
    </ul>
    <h2 {...{
      "id": "commercial-development"
    }}>{`Commercial Development`}</h2>
    <p>{`At Fluid Solar, our R&D team are at the forefront of sustainable commercial development, having completed the world’s first off-grid, solar-thermal powered four storey office building—Fluid Solar House—in Elizabeth Vale, South Australia in 2017. The building’s heating, cooling and lighting system has now operated for more than 2 years completely off the grid, powered by rooftop solar thermal and solar PV panels.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fa2b8e3ace94966e7d96a7b8fa32577f/65878/fluid-solar-house.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.215619694397276%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABypN59qA//8QAGRABAQEBAQEAAAAAAAAAAAAAAQIAAxES/9oACAEBAAEFAkDQTScubmTTE+/M7//EABYRAAMAAAAAAAAAAAAAAAAAAAACE//aAAgBAwEBPwGKk1P/xAAWEQADAAAAAAAAAAAAAAAAAAAAAhP/2gAIAQIBAT8BqxRj/8QAGBABAQEBAQAAAAAAAAAAAAAAADEBIVH/2gAIAQEABj8CrxW8RMf/xAAaEAADAAMBAAAAAAAAAAAAAAAAARExQZHx/9oACAEBAAE/IX0EE20W4LMuotCA5p5R/9oADAMBAAIAAwAAABA8P//EABYRAQEBAAAAAAAAAAAAAAAAAAABYf/aAAgBAwEBPxCQZv/EABcRAAMBAAAAAAAAAAAAAAAAAAABIWH/2gAIAQIBAT8QbqaH/8QAGhABAQEBAAMAAAAAAAAAAAAAAREAITFhof/aAAgBAQABPxBGwoxguEmDoP2updzrBjgkOvM1OrPsb//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Fluid Solar House",
            "title": "Fluid Solar House",
            "src": "/static/fa2b8e3ace94966e7d96a7b8fa32577f/7ef33/fluid-solar-house.jpg",
            "srcSet": ["/static/fa2b8e3ace94966e7d96a7b8fa32577f/c635c/fluid-solar-house.jpg 500w", "/static/fa2b8e3ace94966e7d96a7b8fa32577f/7e3f6/fluid-solar-house.jpg 1000w", "/static/fa2b8e3ace94966e7d96a7b8fa32577f/7ef33/fluid-solar-house.jpg 2000w", "/static/fa2b8e3ace94966e7d96a7b8fa32577f/2d501/fluid-solar-house.jpg 3000w", "/static/fa2b8e3ace94966e7d96a7b8fa32577f/b324a/fluid-solar-house.jpg 4000w", "/static/fa2b8e3ace94966e7d96a7b8fa32577f/65878/fluid-solar-house.jpg 4712w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4ae503b697190c0681a75fd186a7e1a8/8d978/cutaway.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHRjEsA/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEDAhL/2gAIAQEAAQUCq6dq9B6oz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAEDBQAAAAAAAAAAAAAAAAABESICEiFhkf/aAAgBAQAGPwKNzDrQvCKY2f/EABkQAAIDAQAAAAAAAAAAAAAAAAABESFBcf/aAAgBAQABPyGwqLERKLodJxQwf//aAAwDAQACAAMAAAAQ/D//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIUExUf/aAAgBAQABPxA9Y7wkXvsq9XR9oQRBmo9n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Fluid Solar House Cutaway",
            "title": "Fluid Solar House Cutaway",
            "src": "/static/4ae503b697190c0681a75fd186a7e1a8/7ef33/cutaway.jpg",
            "srcSet": ["/static/4ae503b697190c0681a75fd186a7e1a8/c635c/cutaway.jpg 500w", "/static/4ae503b697190c0681a75fd186a7e1a8/7e3f6/cutaway.jpg 1000w", "/static/4ae503b697190c0681a75fd186a7e1a8/7ef33/cutaway.jpg 2000w", "/static/4ae503b697190c0681a75fd186a7e1a8/2d501/cutaway.jpg 3000w", "/static/4ae503b697190c0681a75fd186a7e1a8/b324a/cutaway.jpg 4000w", "/static/4ae503b697190c0681a75fd186a7e1a8/8d978/cutaway.jpg 5000w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`With our patented solar thermal systems and experienced team of designers and engineers, we are well-placed to work with you on your sustainable solar thermal hybrid energy-powered commercial building, from project inception to completion.`}</p>
    <p>{`Whether you’re planning to construct a multi-residential development, small to medium sized office building, educational institution, retail store, or anything other occupied space, we’ll work with your design team to deliver on time and on budget.`}</p>
    {
      /* Docs to Markdown version 1.0β17 */
    }
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4434dcbd5812572fa019e26c7c04433c/7e975/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.160000000000004%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABXjuGiK//xAAYEAADAQEAAAAAAAAAAAAAAAABAhEAEP/aAAgBAQABBQK5mALOaOTf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABYRAAMAAAAAAAAAAAAAAAAAAAABEv/aAAgBAgEBPwG2Uz//xAAXEAADAQAAAAAAAAAAAAAAAAAAASEg/9oACAEBAAY/AiUjWP/EABoQAAIDAQEAAAAAAAAAAAAAAAABESFBUTH/2gAIAQEAAT8h9xRZEOmBBFKrSFLpCTiP/9oADAMBAAIAAwAAABB4D//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EIQ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxAD/8QAHBABAAICAwEAAAAAAAAAAAAAAQAhETFBUXHB/9oACAEBAAE/EEuIBVsTACpw5fly0qTnU1heDXsMdR1j2ByG7qf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Light avenue",
            "title": "Light avenue",
            "src": "/static/4434dcbd5812572fa019e26c7c04433c/7ef33/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg",
            "srcSet": ["/static/4434dcbd5812572fa019e26c7c04433c/c635c/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg 500w", "/static/4434dcbd5812572fa019e26c7c04433c/7e3f6/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg 1000w", "/static/4434dcbd5812572fa019e26c7c04433c/7ef33/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg 2000w", "/static/4434dcbd5812572fa019e26c7c04433c/7e975/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg 2500w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/26206d0b3c0e3a8e950a7d613b9116c7/2972e/Fluid%20Solar%20House%20signage%20southern%20facade.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUBA//EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAe6diV0sn//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhITBCH/2gAIAQEAAQUCtFF4i9WxsLpkl//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAgEBPwGlP//EABgQAQEAAwAAAAAAAAAAAAAAAAAxARAy/9oACAEBAAY/AquuUTD/xAAcEAACAQUBAAAAAAAAAAAAAAAAAREQITFBUdH/2gAIAQEAAT8h2qNnIsXonyRJF//aAAwDAQACAAMAAAAQGN//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qyr//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Q0h//xAAcEAEAAwEAAwEAAAAAAAAAAAABABEhMVFhkdH/2gAIAQEAAT8QUUg+ElYoMleEe5fAfcxDmV1ma/u3+z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Fluid Solar House signage southern facade",
            "title": "Fluid Solar House signage southern facade",
            "src": "/static/26206d0b3c0e3a8e950a7d613b9116c7/7ef33/Fluid%20Solar%20House%20signage%20southern%20facade.jpg",
            "srcSet": ["/static/26206d0b3c0e3a8e950a7d613b9116c7/c635c/Fluid%20Solar%20House%20signage%20southern%20facade.jpg 500w", "/static/26206d0b3c0e3a8e950a7d613b9116c7/7e3f6/Fluid%20Solar%20House%20signage%20southern%20facade.jpg 1000w", "/static/26206d0b3c0e3a8e950a7d613b9116c7/7ef33/Fluid%20Solar%20House%20signage%20southern%20facade.jpg 2000w", "/static/26206d0b3c0e3a8e950a7d613b9116c7/2972e/Fluid%20Solar%20House%20signage%20southern%20facade.jpg 2592w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      